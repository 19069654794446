/* Styles for the Footer */
.header {
    background-color: #0b0c10; /* Dark background */
    color: #61dafb; /* Cyan text */
    padding: 10px;
    text-align: center;
    border-bottom: 1px solid #61dafb; /* Cyan border */
    border-radius: 0 0 10px 10px; /* Rounded bottom corners */
    box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.5); /* Subtle shadow for depth */
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    align-content: stretch;
}

.socials {
    margin-right: 0px;
    margin-left: auto;
}

.title {
    flex-grow: 1;
    font-size: 30px;
    line-height: 30px;
    margin: 0;
    padding: 0;
    padding-top: 2px;
}

.donations {
    margin-left: 0px;
    margin-right: auto;
}

.header a {
    color: #61dafb; /* Cyan text */
    text-decoration: none;
    transition: color 0.3s; /* Smooth transition for hover */
}

.icon {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-focus-ring-color: rgba(255, 255, 255, 0);
}

.icon img:last-child {
    display: none;  
}
.icon:hover img:last-child {
    display: inline-block;  
}
.icon:hover img:first-child {
    display: none;  
}

.x-icon {
    width: 30px;
    height: 30px;
}

.heart-icon {
    width: 30px;
    height: 30px;
}

@media only screen and (max-width: 600px) {
    .x-icon {
        width: 15px;
        height: 15px;
    }

    .heart-icon {
        width: 15px;
        height: 15px;
    }

    .title {
        font-size: 15px;
        line-height: 15px;
        padding-top: 4px;
    }
  }