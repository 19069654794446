/* Styles for the Footer */
.footer {
    background-color: #0b0c10; /* Dark background */
    color: #61dafb; /* Cyan text */
    padding: 0px;
    text-align: center;
    border-top: 1px solid #61dafb; /* Cyan border */
    margin-top: 40px; /* Space above the footer */
    border-radius: 0 0 10px 10px; /* Rounded bottom corners */
    box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.5); /* Subtle shadow for depth */
}

.footer a {
    color: #61dafb; /* Cyan text */
    text-decoration: none;
    margin: 0 10px;
    transition: color 0.3s; /* Smooth transition for hover */
}

.footer a:hover {
    color: #45a29e; /* Lighter cyan on hover */
}

.social-button {
    display: inline-block;
    width: 40px;
    height: 40px;
    /* background-color: #61dafb; Cyan background */
    color: #61dafb; /* Black text/icon */
    border-radius: 50%; /* Circular button */
    text-align: center;
    line-height: 55px; /* Center the icon */
    margin: 0 10px;
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover */
    font-size: 20px; /* Size of the icon */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Subtle shadow for depth */
}

.social-button:hover {
    /* background-color: #45a29e; Lighter cyan on hover */
    color: #000000; /* Black text/icon on hover */
}

.social-button img:last-child {
    display: none;  
}
.social-button:hover img:last-child {
    display: inline-block;  
}
.social-button:hover img:first-child {
    display: none;  
}