/* Base Styles for the Table */
body {
    background-color: #000000; /* Black background */
    color: #61dafb; /* React cyan text color */
    font-family: 'Arial', sans-serif; /* Clean font */
}

table {
    width: 80%;
    margin: 0px auto;
    border-collapse: collapse;
    background-color: #0b0c10; /* Very dark gray background for the table */
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5); /* Subtle shadow for depth */
}

th, td {
    border: 1px solid #61dafb; /* React cyan border */
    padding: 10px;
    text-align: left;
    color: #61dafb; /* React cyan text color */
}

th {
    background-color: #1f2833; /* Slightly lighter header background */
    color: #ffffff; /* White text for headers */
    text-transform: uppercase; /* Uppercase headers */
    letter-spacing: 1px; /* Spacing between letters */
}

td {
    background-color: #0b0c10; /* Cell background */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
}

tr:nth-child(even) td {
    background-color: #1f2833; /* Alternate row color */
}

td:hover {
    background-color: #45a29e; /* Hover effect for cells */
}

/* Optional: Adding a glowing border to the table */
table {
    border: 1px solid rgba(97, 218, 251, 0.2);
    position: relative;
}

table:before {
    content: '';
    position: absolute;
    top: -8px;
    left: -8px;
    right: -8px;
    bottom: -8px;
    border: 2px solid rgba(97, 218, 251, 0.2);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    z-index: -1;
    filter: blur(5px);
}
