/* Base Styles for the Body */
body {
    background-color: #000000; /* Black background */
    color: #61dafb; /* React cyan text color */
    /* font-family: 'Arial', sans-serif; Clean font */
}

/* Container for the Form */
.form-container {
    width: 80%;
    margin: 40px auto;
    padding: 20px;
    background-color: #0b0c10; /* Very dark gray background */
    border: 1px solid #61dafb; /* Cyan border */
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5); /* Subtle shadow for depth */
    border-radius: 10px; /* Rounded corners */
}

/* Styles for the Dropdown Menu */
select {
    width: 20%;
    min-width: 250px;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #61dafb; /* Cyan border */
    background-color: #1f2833; /* Slightly lighter background */
    color: #ffffff; /* White text */
    border-radius: 5px; /* Rounded corners */
    font-size: 16px; /* Slightly larger text */
    transition: border-color 0.3s, box-shadow 0.3s; /* Smooth transition for focus */
    appearance: none; /* Remove default dropdown arrow */
    background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"><path fill="%2361dafb" d="M2 0L0 2h4zM2 5L0 3h4z"/></svg>'); /* Custom arrow */
    background-repeat: no-repeat;
    background-position: right 10px center;
    outline: none; /* Remove default outline */
}

select:focus {
    border-color: #45a29e; /* Cyan hover effect */
    box-shadow: 0 0 5px #45a29e; /* Cyan shadow */
    outline: none; /* Remove default outline */
}

/* Styles for the Dropdown Options */
select option {
    background-color: #1f2833; /* Dark background */
    color: #ffffff; /* White text */
}

select option:hover,
select option:focus,
select option:checked {
    background-color: #45a29e; /* Cyan highlight for options */
    color: #000000; /* Black text for highlighted options */
}

/* Remove default blue highlight */
select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}

select:focus::-ms-value {
    background: transparent;
}

/* Styles for the Labels */
label {
    margin: 10px 0;
    display: block;
    font-size: 14px; /* Smaller text */
    color: #61dafb; /* Cyan text color */
}

/* Styles for the Submit Button */
input[type="submit"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #61dafb; /* Cyan border */
    background-color: #0b0c10; /* Dark background */
    color: #61dafb; /* Cyan text */
    border-radius: 5px; /* Rounded corners */
    font-size: 16px; /* Larger text */
    cursor: pointer; /* Pointer cursor */
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover */
}

input[type="submit"]:hover {
    background-color: #61dafb; /* Cyan background on hover */
    color: #000000; /* Black text on hover */
}
